<template>
    <html class="h-full bg-gray-100">
      <body class="h-full">
        
      </body>
    </html>
  </template>
  
  <script>
  export default {
    name: 'HomePage'
  }
  </script>